import { ActionContext } from 'vuex';
import { StoreAikaState } from './state';
import axios from 'axios';

const API_URL = process.env.VUE_APP_URL_BACKEND;

export default {
    async fetchCategories({ commit }: ActionContext<StoreAikaState, any>) {
        try {
            const response = await axios.get(`${API_URL}api/auth/list-categories/`);
            commit('setCategories', response.data.categories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }    
    },
    async fetchProducts({ commit }: ActionContext<StoreAikaState, any>) {
        try {
            const response = await axios.get(`${API_URL}api/auth/list-products/`);
            commit('setProducts', response.data.products);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }
};