import { ActionContext, ActionTree } from 'vuex';
import Cookies from 'js-cookie'; // Importar js-cookie
import axios from 'axios';
import { CartCreditState, Category, Product } from '../types';
import { RootState } from '@/store/types';


const API_URL = process.env.VUE_APP_URL_BACKEND;

const actions: ActionTree<CartCreditState, RootState> =  {
    async loadUserInfo({ commit }, payload: { document: string }) {
        try {
            // Obtener el token de las cookies
            const token = Cookies.get('authToken'); // Recuperar el token de las cookies
            if (!token) {
                // console.warn('El usuario no está autenticado. Saltando la carga de información del usuario.'); Silencioso: No se hace nada si no hay token
                return;    
            }
    
            // Configurar Axios con el token de autenticación
            axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            axios.defaults.withCredentials = true;
    
            const response = await axios.post(`${API_URL}api/auth/get-user-info/`, { document: payload.document });  // Llamada al nuevo endpoint
            console.log('LoadUserInfo', response);
            
            // Mapear la respuesta y guardarla en el estado de Vuex
            const userInfo = response.data;
            const newToken = response.headers.authorization;

            // Si se recibe un nuevo token en la respuesta, actualizarlo
            if (newToken) {
                Cookies.set('authToken', newToken); // Actualizar el token en las cookies
                commit('auth/SET_TOKEN', newToken, { root: true }); // Actualizar el token en el estado de autenticación
                axios.defaults.headers.common['Authorization'] = `Token ${newToken}`;
            }
    
            commit('SET_USER_INFO', userInfo); // Guardar la información del usuario en el estado de Vuex
        } catch (error) {
            console.error('Error loading user info:', error);
            commit('SET_ERROR', 'No se pudo cargar la información del usuario');
        }
    },
    
    async loadCategories({ commit }) {
        try {

            const response = await axios.get(`${API_URL}api/auth/list-categories/`);

            console.log('Categories response:', response.data);  // Verificar respuesta de categorías
            
            const categories: Category[] = response.data.categories.map((category: any) => ({
                id: category.id,
                name: category.name,
                description: category.description,
            }));
            
            commit('SET_CATEGORIES', categories);
        } catch (error: any) {
            console.error('Error loading categories:', error);
            commit('SET_ERROR', 'No se pudieron cargar las categorías')
        }
    },
    
    async loadProducts({ commit }) {
        try {

            const response = await axios.get(`${API_URL}api/auth/list-products/`);
            console.log('Products response:', response.data);  // Verificar respuesta de productos
            
            // Extraer y mapear los productos desde la respuesta
            const products: Product[] = response.data.products.map((product: any) => ({
                id: product.id,
                name: product.name,
                description: product.description,
                category: product.category,
                price: parseFloat(product.price),
                // images: `${API_URL}${product.images}`, // Directamente la URL de la imagen
                images: product.images,
            }));

            commit('SET_PRODUCTS', products);

        } catch (error: any) {
            console.error('Error loading products:', error);
            commit('SET_ERROR', 'No se puedieron cargar los productos');
        }
    },

    async addToCart({ commit, rootState }, payload: { document: string, id: number, quantity: number }) {
        commit('SET_LOADING', true);
        commit('SET_ERROR', null);

        try {

            // Obtener el token de las cookies
            const token = Cookies.get('authToken'); // Recuperar el token de las cookies
            if (!token) {
                throw new Error('No se encontró el token de autenticación');   
            }

            // Configurar Axios con el token de autenticación
            axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            axios.defaults.withCredentials = true;

            // Formatear el payload
            const formattedPayload = {
                document: payload.document,
                id: payload.id,
                quantity: payload.quantity
            };

            console.log('Payload enviado desde action.ts:', formattedPayload);

            // Enviar la solicitud POST para agregar el producto al carrito
            const response = await axios.post(`${API_URL}api/auth/add-to-cart/`, formattedPayload,
                { headers: { 'Content-Type': 'application/json' }    
            });

            // Mapear la respuesta y guardarla en el estado de Vuex
            const userInfo = response.data;

            const newToken = response.headers.authorization;

            // Si se recibe un nuevo token en la respuesta, actualizarlo
            if (newToken) {
                Cookies.set('authToken', newToken); // Actualizar el token en las cookies
                commit('auth/SET_TOKEN', newToken, { root: true }); // Actualizar el token en el estado de autenticación
                axios.defaults.headers.common['Authorization'] = `Token ${newToken}`;
            }

            // Si la respuesta es exitosa, guardamos el carrito actualizado
            commit('SET_CART', response.data.cart);
            commit('SET_LOADING', false);
            console.log('Recibir que se agrego desde el actions:', response);
            return response.data; 
        } catch (error: any) {                       
            const err = error as { response?: { data?: any }; message?: string }; // Conversión explícita
            commit('SET_ERROR', err.response?.data || err.message || 'Error desconocido');
            commit('SET_LOADING', false);
            throw error;
        }
    },

    // Acción para manejar los productos pendientes en el carrito
    async handlePendingCart({ commit, rootState }) {
        const pendingCart = localStorage.getItem('pendingCart');
        if (pendingCart) {
        try {
            const product = JSON.parse(pendingCart);

            // Verificar si el usuario está autenticado y tiene un documento válido
            const user = rootState.cartCredit.userInfo || null;
            const documentValue = user?.document;

            if (!documentValue) {
            console.error('Documento del usuario no encontrado tras autenticación.');
            return;
            }

            // Construir y procesar el payload
            const payload = {
            document: documentValue,
            id: product.id,
            quantity: product.quantity,
            };

            // Despachar acción para agregar el producto al carrito
            await commit('cartCredit/addToCart', payload);

            // Limpiar el almacenamiento local
            localStorage.removeItem('pendingCart');

            console.log('Producto agregado correctamente después de autenticación:', payload);
        } catch (error) {
            console.error('Error procesando el carrito pendiente:', error);
        }
        }
    },

    async fetchCart({ commit, rootState }) {
        try {
            // Obtener el token de las cookies
            const token = Cookies.get('authToken'); // Recuperar el token de las cookies
            if (!token) {
                throw new Error('No se encontró el token de autenticación');   
            }

            // Configurar Axios con el token de autenticación
            axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            axios.defaults.withCredentials = true;

            const response = await axios.get(`${API_URL}api/auth/view-cart/`);
            commit('SET_CART_ITEMS', response.data.items || []);
        } catch (error: any) {
            const err = error.response?.data || error.message || 'Error al obtener el carrito';
            commit('SET_ERROR', err);
        }
    },

    async removeFromCart({ commit }, itemId: number) {
        try {
            // Obtener el token de las cookies
            const token = Cookies.get('authToken'); // Recuperar el token de las cookies
            if (!token) {
                throw new Error('No se encontró el token de autenticación');   
            }

            // Configurar Axios con el token de autenticación
            axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            axios.defaults.withCredentials = true;

            const response = await axios.delete(`${API_URL}api/auth/cart/item/${itemId}/delete/`,
                { headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                // Actualizar el estado del carrito después de eliminar el producto
                commit('REMOVE_CART_ITEM', itemId);
                console.log('Producto eliminado con éxito');
            }    
        } catch (error) {
            console.error('Error eliminando el producto del carrito:', error);
        }
    },

    async logout({ commit }) {
        commit('LOGOUT');
        localStorage.removeItem('authToken');
        Cookies.remove('authToken');
        Cookies.remove('document');
        delete axios.defaults.headers.common['Authorization'];
    
        return Promise.resolve(); // Esto asegura que logout retorne una promesa
    }
};

export default actions;