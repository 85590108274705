import { CartCreditState, CartItem, Category, Product } from '../types';

const state: CartCreditState = {
  creditId: null,
  amount: '',
  installments: 0,
  totalAmount: '',
  payments: [],
  error: null,
  loading: false,
  categories: [] as Category[], // Lista inicial vacía de categorías
  products: [] as Product[],   // Lista inicial vacía de productos
  creditRequestStatus: '',
  // cartItems: [] as (Product & { quantity: number })[],
  cartItems: [] as CartItem[], // Lista inicial vacía de elementos del carrito
  userInfo: {
    first_name: null,
    second_name: null,
    last_name: null,
    second_surname: null,
    document: null,
    address: null
  },
  cart: [],  // Aquí guardamos los datos del carrito
};

export default state;