import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { RootState } from './types';  // Asegúrate de que estás importando RootState desde el archivo correcto
import auth from './authentication-user';
import simulateCredit from './simulate-credit';
import documentUpload from './document-upload';
import Cookies from 'js-cookie'; // Importa js-cookie para manejar las cookies
import router from '@/router'; // Asegúrate de importar tu enrutador adecuadamente
import documentViewer from './document-viewer';
import documentSign from './document-sign';
import documentSignSendotp from './document-sign-sendotp';
import cartCredit from './cart-credit';
import StoreAika from './store-aika';

const store = createStore<RootState>({
  modules: {
    auth,
    simulateCredit,
    documentUpload,
    documentViewer,
    documentSignSendotp,
    documentSign,
    cartCredit,
    StoreAika
  },
  // Middleware para manejar la autenticación y redirección
  plugins: [
    (store) => {
      store.subscribeAction(async () => {
        const token = Cookies.get('authToken');
        const currentRoute = router.currentRoute.value.fullPath;
  
        // Guardar el flujo actual en localStorage
        if (currentRoute.startsWith('/store-aika')) {
          localStorage.setItem('lastFlow', 'storeAika');
        } else {
          localStorage.setItem('lastFlow', 'default'); // Flujo predeterminado
        }
        
        // Redirigi solo si no es `/store-aika`
        if (!token && currentRoute !== '/store-aika') {
          router.push('/');
        }

        // Redirigir según el flujo guardado si no hay token
        // if (!token) {
        //   const lastFlow = localStorage.getItem('lastFlow');
        //   if (lastFlow === 'storeAika' && currentRoute !== '/store-aika') {
        //     router.push('/store-aika');
        //   } else if (currentRoute !== '/') {
        //     router.push('/'); // Redirige al login
        //   }
        // }
      });
    },
  ]
  // plugins: [store => {
  //   store.subscribeAction(async (action) => {
  //     // Verificar el token al ejecutar acciones, excepto el logout
  //     if (action.type !== 'auth/logout') {
  //       const token = Cookies.get('authToken');
  //       const currentRoute = router.currentRoute.value.fullPath;
  //       if (!token && currentRoute !== '/store-aika') {
  //         // Redirigir a la página de inicio de sesión si no hay token
  //         router.push('/');
  //       }
  //     }
  //   });
  // }]
});

export function useStore(): Store<RootState> {
  return baseUseStore();
}


export default store;