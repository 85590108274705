import { Module } from 'vuex';
import { StoreAikaState, state } from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const StoreAika: Module<StoreAikaState, any> = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

export default StoreAika;