import { StoreAikaState } from './state';

export default {
    setCategories(state: StoreAikaState, categories: Array<{ name: string }>) {
        state.categories = categories;
    },
    setProducts(state: StoreAikaState, products: Array<any>) {
        state.products = products;
    },
    setFilteredProducts(state: StoreAikaState, filteredProducts: Array<any>) {
        state.filteredProducts = filteredProducts;
    }
};