export interface StoreAikaState {
    categories: Array<{ name: string }>;
    products: Array<any>;
    filteredProducts: Array<any>;
}

export const state: StoreAikaState = {
    categories: [],
    products: [],
    filteredProducts: []
};