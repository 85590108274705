export interface SimulateCreditState {
    amount: number | null;
    installments: number | null;
    days: number | null;
    is_from_cart: boolean | null;
    simulationResult: {
        loan_amount: number;
        monthly_salary: number;
        term_months: number;
        total_interest: number;
        total_amount: number;
        monthly_payment: number;
        payment_due_date: string;
        max_installments: number;
        credit_type: string;
        max_amount: number;
        min_amount: number;
    } | null;
}

const state: SimulateCreditState = {
    amount: 0, // Valores iniciales, ajusta según tu lógica
    installments: 0,
    days: 0,
    is_from_cart: false,
    simulationResult: null,
};

export default state;