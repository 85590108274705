import { CartCreditState, Category, Product } from "../types";
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import state from "../simulate-credit/state";

const getters: GetterTree<CartCreditState, RootState> = {
    // creditId: (state: CartCreditState) => state.creditId,
    // amount: (state: CartCreditState) => state.amount,
    // installments: (state: CartCreditState) => state.installments,
    // totalAmount: (state: CartCreditState) => state.totalAmount,
    payments: (state: CartCreditState) => state.payments,
    error: (state: CartCreditState) => state.error,
    loading: (state: CartCreditState) => state.loading,
    // Getter para productos
    products: (state: CartCreditState): Product[] => {
        return state.products;
    },
    // Getter para categorías
    categories: (state: CartCreditState): Category[] => {
        return state.categories;
    },
    cartItemCount: (state: CartCreditState) => {
        return state.cartItems.reduce((total, item) => total + item.quantity, 0);    
    },
    userInfo: (state: CartCreditState) => state.userInfo,
    cart(state) {
        return state.cart;
    },
    // obtener ek subtotal para cada producto
    cartItems(state) {
        return state.cartItems.map(item => ({
            id: item.id,
            name: item.name,
            subtotal: item.subtotal,
        }));
    },
    // Calcular el total del carrito (suma de todos los subtotales)
    cartTotal(state) {
        return Array.isArray(state.cartItems) ? state.cartItems.reduce((total, item) => total + item.subtotal, 0) : 0;
    },
    uniqueProductCount(state) {
        if (Array.isArray(state.cartItems)) {
            return state.cartItems.reduce((count, item) => count + item.quantity, 0);
        }
        return 0; // Return 0 if cartItems is not an array or is empty
    }
};

export default getters;