import { CartCreditState, Category, Product, CartItem } from "../types";
import { MutationTree } from 'vuex';
import state from "../simulate-credit/state";

const mutations: MutationTree<CartCreditState> = {
    // SET_CREDIT_ID(state: CartCreditState, creditId: number) {
    //     state.creditId = creditId;
    // },
    // SET_AMOUNT(state: CartCreditState, amount: string) {
    //     state.amount = amount;
    // },
    // SET_INSTALLMENTS(state: CartCreditState, installments: number) {
    //     state.installments = installments;
    // },
    // SET_TOTAL_AMOUNT(state: CartCreditState, totalAmount: string) {
    //     state.totalAmount = totalAmount;
    // },
    // SET_PAYMENTS(state: CartCreditState, payments: any[]) {
    //     state.payments = payments;
    // },
    SET_CATEGORIES(state, categories: Category[]) {
        state.categories = categories;
    },
    SET_PRODUCTS(state, products: Product[]) {
        state.products = products;
    },
    SET_LOADING(state: CartCreditState, loading: boolean) {
        state.loading = loading;
    },
    SET_USER_INFO(state, userInfo) {
        state.userInfo = userInfo;
    },
    SET_ERROR(state: CartCreditState, error: string) {
        state.error = error;
    },
    SET_CREDITREQUESTSTATUS(state: CartCreditState, status: string) {
        state.creditRequestStatus = status;
    }, 
    ADD_TO_CART(state, { product, quantity }: {product: Product, quantity: number }) {
        // Calcular el subtotal antes de agregar al carrito
        const subtotal = product.price * quantity;

        state.cartItems.push({
            id: product.id,
            name: product.name,
            price: product.price,
            quantity: quantity,  // Asegurarte de que quantity esté presente
            subtotal: subtotal,   // Asegurarte de que el subtotal esté presente
            images: product.images,
        }); // Agrega el producto al carrito
    },
    SET_CART(state, cartItems) {
        state.cartItems = cartItems;
    },
    SET_CART_ITEMS(state, items: any[]) {
        const API_URL = process.env.VUE_APP_URL_BACKEND;

        state.cartItems = Array.isArray(items) ? items.map((item: any): CartItem => ({
            id: item.id,
            name: item.product_name,
            price: parseFloat(item.price),
            quantity: item.quantity,
            subtotal: parseFloat(item.subtotal),
            images: Array.isArray(item.images)
            ? item.images.map((img: string) => img.startsWith('/media') ? `${API_URL}${img}` : img) // Mapear todas las URLs
            : [], // Si no es un array, asignar un array vacío
        })) : [];
    },
    CLEAR_USER_INFO(state) {
        state.userInfo = {
            first_name: null,
            second_name: null,
            last_name: null,
            second_surname: null,
            document: null,
            address: null
        };
    },
    REMOVE_CART_ITEM(state, itemId: number) {
        state.cartItems = state.cartItems.filter((item) => item.id !== itemId);
    },
};

export default mutations;