import { MutationTree } from 'vuex';
import { SimulateCreditState } from './state';

const mutations: MutationTree<SimulateCreditState> = {
    SET_SIMULATION_RESULT(state, result) {
        state.simulationResult = result;
    },

    SET_AMOUNT(state, amount) {
        state.amount = amount;
    } // Para que me actualice en el componente el amount con la respuesta de mi backend con el min_amount
};

export default mutations;